import { Country } from "./Country";
import { CountrySet } from "./CountrySet";
import { DataProvider } from "./DataProvider";
import { QuestionType } from "./QuestionType";

export class Level {
  constructor(countrySets: Array<CountrySet>) {
    let dataProvider = new DataProvider();

    // select one countryset to use for this level
    let selectedCountryset =
      countrySets[Math.floor(Math.random() * countrySets.length)];

    // randomly select one country for the question
    this.questionedCountry =
      dataProvider.getRandomCountryByCountrySet(selectedCountryset);

    // randomly select 3 false answeres as alternatives
    for (let i = 0; i < 3; i++) {
      this.falseAnswers.push(
        dataProvider.getRandomCountryByCountrySet(selectedCountryset, [
          this.questionedCountry,
          ...this.falseAnswers,
        ])
      );
    }

    // combine and mix answers for display
    this.mixedAnswers = [this.questionedCountry, ...this.falseAnswers];
    this.shuffleArray(this.mixedAnswers);

    // randomly select question type
    this.questionType =
      this.possibleQuestionTypes[
        Math.floor(Math.random() * this.possibleQuestionTypes.length)
      ];

    // set question human readable
    this.setQuestion();
  }

  setQuestion = function () {
    if (this.questionType == QuestionType.AskForCapital) {
      this.question =
        "What is the capital of " + this.questionedCountry.country + "?";
    } else {
      this.question =
        "" +
        this.questionedCountry.capital +
        " is the capital of which country?";
    }
  };

  showAnswers = function () {
    this.answersVisible = true;
  };

  solve = function (correctAnswer: boolean) {
    if (correctAnswer) {
      window.navigator.vibrate([100]);
    } else {
      window.navigator.vibrate([100, 400, 100]);
    }

    this.correctnessVisible = true;
  };

  shuffleArray = function (array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  question: String = "";
  questionedCountry: Country;
  falseAnswers: Array<Country> = [];
  mixedAnswers: Array<Country> = [];
  answersVisible: false;
  correctnessVisible: false;
  questionType: QuestionType;
  possibleQuestionTypes: Array<QuestionType> = [
    QuestionType.AskForCapital,
    QuestionType.AskForCountry,
  ];
}
