import { CountrySet } from "./CountrySet";
import { DataProvider } from "./DataProvider";
import { Level } from "./Level";

export function game() {
  return {
    init: function () {
      this.dataProvider = new DataProvider();

      if (this.activeCountrySets.length === 0) {
        this.activeCountrySets = [CountrySet.Europe];
      }

      this.nextLevel();
    },
    nextLevel: function () {
      this.level = new Level(this.activeCountrySets);
    },
    level: null,
    dataProvider: null,
    showSettings: false,
    activeCountrySets: this.$persist([]),
    countrySets: [
      {
        label: "Europe",
        type: CountrySet.Europe,
      },
      {
        label: "Asia",
        type: CountrySet.Asia,
      },
      {
        label: "North America",
        type: CountrySet.NorthAmerica,
      },
      {
        label: "South America",
        type: CountrySet.SouthAmerica,
      },
      {
        label: "Africa",
        type: CountrySet.Africa,
      },
      {
        label: "Oceania",
        type: CountrySet.Oceania,
      },
    ],
  };
}
