import { Country } from "./Country";
import { CountrySet } from "./CountrySet";

export class DataProvider {
  countrySets: Array<CountrySet>;

  getRandomCountryByCountrySet(
    countrySet: CountrySet,
    excludeCountries?: Array<Country>
  ) {
    let filteredCountryList = this.countryList.filter((country) => {
      if (
        country.countrySets.includes(countrySet) &&
        (!excludeCountries || !excludeCountries.includes(country))
      ) {
        return country;
      }
    });
    let countryId = Math.floor(Math.random() * filteredCountryList.length);

    return filteredCountryList[countryId];
  }

  countryList: Array<Country> = [
    {
      country: "Afghanistan",
      capital: "Kabul",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Albania",
      capital: "Tirana (Tirane)",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Algeria",
      capital: "Algiers",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Andorra",
      capital: "Andorra la Vella",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Angola",
      capital: "Luanda",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Antigua and Barbuda",
      capital: "Saint John's",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Argentina",
      capital: "Buenos Aires",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Armenia",
      capital: "Yerevan",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Australia",
      capital: "Canberra",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Austria",
      capital: "Vienna",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Azerbaijan",
      capital: "Baku",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Bahamas",
      capital: "Nassau",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Bahrain",
      capital: "Manama",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Bangladesh",
      capital: "Dhaka",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Barbados",
      capital: "Bridgetown",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Belarus",
      capital: "Minsk",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Belgium",
      capital: "Brussels",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Belize",
      capital: "Belmopan",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Benin",
      capital: "Porto Novo",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Bhutan",
      capital: "Thimphu",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Bolivia",
      capital: "La Paz (de facto), Sucre (de jure)",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Bosnia and Herzegovina",
      capital: "Sarajevo",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Botswana",
      capital: "Gaborone",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Brazil",
      capital: "Brasilia",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Brunei",
      capital: "Bandar Seri Begawan",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Bulgaria",
      capital: "Sofia",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Burkina Faso",
      capital: "Ouagadougou",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Burundi",
      capital: "Gitega",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Cambodia",
      capital: "Phnom Penh",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Cameroon",
      capital: "Yaounde",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Canada",
      capital: "Ottawa",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Cape Verde",
      capital: "Praia",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Central African Republic",
      capital: "Bangui",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Chad",
      capital: "N'Djamena",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Chile",
      capital: "Santiago",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "China",
      capital: "Beijing",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Colombia",
      capital: "Bogota",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Comoros",
      capital: "Moroni",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Congo, Democratic Republic of the",
      capital: "Kinshasa",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Congo, Republic of the",
      capital: "Brazzaville",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Costa Rica",
      capital: "San Jose",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Côte d'Ivoire (Ivory Coast)",
      capital: "Yamoussoukro",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Croatia",
      capital: "Zagreb",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Cuba",
      capital: "Havana",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Cyprus",
      capital: "Nicosia",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Czech Republic (Czechia)",
      capital: "Prague",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Denmark",
      capital: "Copenhagen",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Djibouti",
      capital: "Djibouti",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Dominica",
      capital: "Roseau",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Dominican Republic",
      capital: "Santo Domingo",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "East Timor",
      capital: "Dili",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Ecuador",
      capital: "Quito",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Egypt",
      capital: "Cairo",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "El Salvador",
      capital: "San Salvador",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Equatorial Guinea",
      capital: "Malabo",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Eritrea",
      capital: "Asmara",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Estonia",
      capital: "Tallinn",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Eswatini (Swaziland)",
      capital: "Mbabana",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Ethiopia",
      capital: "Addis Ababa",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Federated States of Micronesia",
      capital: "Palikir",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Fiji",
      capital: "Suva",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Finland",
      capital: "Helsinki",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "France",
      capital: "Paris",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Gabon",
      capital: "Libreville",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Gambia",
      capital: "Banjul",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Georgia",
      capital: "Tbilisi",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Germany",
      capital: "Berlin",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Ghana",
      capital: "Accra",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Greece",
      capital: "Athens",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Grenada",
      capital: "Saint George's",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Guatemala",
      capital: "Guatemala City",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Guinea",
      capital: "Conakry",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Guinea-Bissau",
      capital: "Bissau",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Guyana",
      capital: "Georgetown",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Haiti",
      capital: "Port au Prince",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Honduras",
      capital: "Tegucigalpa",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Hungary",
      capital: "Budapest",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Iceland",
      capital: "Reykjavik",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "India",
      capital: "New Delhi",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Indonesia",
      capital: "Jakarta",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Iran",
      capital: "Tehran",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Iraq",
      capital: "Baghdad",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Ireland",
      capital: "Dublin",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Israel",
      capital: "Jerusalem",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Italy",
      capital: "Rome",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Jamaica",
      capital: "Kingston",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Japan",
      capital: "Tokyo",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Jordan",
      capital: "Amman",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Kazakhstan",
      capital: "Nur-Sultan",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Kenya",
      capital: "Nairobi",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Kiribati",
      capital: "Tarawa Atoll",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Kosovo",
      capital: "Pristina",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Kuwait",
      capital: "Kuwait City",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Kyrgyzstan",
      capital: "Bishkek",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Laos",
      capital: "Vientiane",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Latvia",
      capital: "Riga",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Lebanon",
      capital: "Beirut",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Lesotho",
      capital: "Maseru",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Liberia",
      capital: "Monrovia",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Libya",
      capital: "Tripoli",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Liechtenstein",
      capital: "Vaduz",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Lithuania",
      capital: "Vilnius",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Luxembourg",
      capital: "Luxembourg",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Madagascar",
      capital: "Antananarivo",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Malawi",
      capital: "Lilongwe",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Malaysia",
      capital: "Kuala Lumpur",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Maldives",
      capital: "Male",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Mali",
      capital: "Bamako",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Malta",
      capital: "Valletta",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Marshall Islands",
      capital: "Majuro",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Mauritania",
      capital: "Nouakchott",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Mauritius",
      capital: "Port Louis",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Mexico",
      capital: "Mexico City",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Moldova",
      capital: "Chisinau",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Monaco",
      capital: "Monaco",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Mongolia",
      capital: "Ulaanbaatar",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Montenegro",
      capital: "Podgorica",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Morocco",
      capital: "Rabat",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Mozambique",
      capital: "Maputo",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Myanmar (Burma)",
      capital: "Nay Pyi Taw",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Namibia",
      capital: "Windhoek",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Nauru",
      capital: "No official capital",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Nepal",
      capital: "Kathmandu",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Netherlands",
      capital: "Amsterdam",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "New Zealand",
      capital: "Wellington",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Nicaragua",
      capital: "Managua",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Niger",
      capital: "Niamey",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Nigeria",
      capital: "Abuja",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "North Korea",
      capital: "Pyongyang",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "North Macedonia (Macedonia)",
      capital: "Skopje",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Norway",
      capital: "Oslo",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Oman",
      capital: "Muscat",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Pakistan",
      capital: "Islamabad",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Palau",
      capital: "Melekeok",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Palestine",
      capital: "Jerusalem",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Panama",
      capital: "Panama City",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Papua New Guinea",
      capital: "Port Moresby",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Paraguay",
      capital: "Asuncion",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Peru",
      capital: "Lima",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Philippines",
      capital: "Manila",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Poland",
      capital: "Warsaw",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Portugal",
      capital: "Lisbon",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Qatar",
      capital: "Doha",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Romania",
      capital: "Bucharest",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Russia",
      capital: "Moscow",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Rwanda",
      capital: "Kigali",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Saint Kitts and Nevis",
      capital: "Basseterre",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Saint Lucia",
      capital: "Castries",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Saint Vincent and the Grenadines",
      capital: "Kingstown",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Samoa",
      capital: "Apia",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "San Marino",
      capital: "San Marino",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Sao Tome and Principe",
      capital: "Sao Tome",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Saudi Arabia",
      capital: "Riyadh",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Senegal",
      capital: "Dakar",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Serbia",
      capital: "Belgrade",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Seychelles",
      capital: "Victoria",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Sierra Leone",
      capital: "Freetown",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Singapore",
      capital: "Singapore",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Slovakia",
      capital: "Bratislava",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Slovenia",
      capital: "Ljubljana",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Solomon Islands",
      capital: "Honiara",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Somalia",
      capital: "Mogadishu",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "South Africa",
      capital: "Pretoria, Bloemfontein, Cape Town (all de facto)",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "South Korea",
      capital: "Seoul",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "South Sudan",
      capital: "Juba",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Spain",
      capital: "Madrid",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Sri Lanka",
      capital: "Sri Jayawardenapura Kotte",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Sudan",
      capital: "Khartoum",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Suriname",
      capital: "Paramaribo",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Sweden",
      capital: "Stockholm",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Switzerland",
      capital: "Bern",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Syria",
      capital: "Damascus",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Taiwan",
      capital: "Taipei",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Tajikistan",
      capital: "Dushanbe",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Tanzania",
      capital: "Dar es Salaam (de facto), Dodoma (de jure)",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Thailand",
      capital: "Bangkok",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Togo",
      capital: "Lome",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Tonga",
      capital: "Nuku'alofa",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Trinidad and Tobago",
      capital: "Port of Spain",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Tunisia",
      capital: "Tunis",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Türkiye (Turkey)",
      capital: "Ankara",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Turkmenistan",
      capital: "Ashgabat",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Tuvalu",
      capital: "Funafuti",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Uganda",
      capital: "Kampala",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Ukraine",
      capital: "Kyiv or Kiev",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "United Arab Emirates",
      capital: "Abu Dhabi",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "United Kingdom",
      capital: "London",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "United States",
      capital: "Washington D.C.",
      countrySets: [CountrySet.NorthAmerica],
    },
    {
      country: "Uruguay",
      capital: "Montevideo",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Uzbekistan",
      capital: "Tashkent",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Vanuatu",
      capital: "Port Vila",
      countrySets: [CountrySet.Oceania],
    },
    {
      country: "Vatican City",
      capital: "Vatican City",
      countrySets: [CountrySet.Europe],
    },
    {
      country: "Venezuela",
      capital: "Caracas",
      countrySets: [CountrySet.SouthAmerica],
    },
    {
      country: "Vietnam",
      capital: "Hanoi",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Yemen",
      capital: "Sana'a",
      countrySets: [CountrySet.Asia],
    },
    {
      country: "Zambia",
      capital: "Lusaka",
      countrySets: [CountrySet.Africa],
    },
    {
      country: "Zimbabwe",
      capital: "Harare",
      countrySets: [CountrySet.Africa],
    },
  ];
}
